<template>
  <v-app>
    <v-card width="95%" class="mx-auto mt-5" v-if="clinics">
      <v-card-title> お客様のクリニック登録方法について </v-card-title>
      <v-card-text>
        <p>
          <b>
            診察券をご用意の上、クリニック名を選択いただいてお進みください。
          </b>
        </p>
      </v-card-text>
      <v-list-item three-line v-for="my in clinics" :key="my.id">
        <v-list-item-content class="my-n3">
          <v-list-item-title class="my-n3">
            {{ my.name }}
            <v-btn outlined rounded text v-on:click="selected(my)">選択</v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-card width="95%" class="mx-auto mt-5" v-if="!clinics">
      <v-card-title> お客様のクリニック登録方法について </v-card-title>
      <v-card-text>
        <p>
          <b>
            診察券をご用意の上、クリニックでお受け取りになったパンフレットの「QRコード」をスマートフォンのカメラで読み取りください。
          </b>
        </p>
        <img src="/images/source/smartphone_qr_code.png" />
        <v-form class="pa-10">
          <v-row>
            <p>
              パンフレットをお持ちでない方は、クリニックの代表電話番号を入力して検索してください。
              <br />
              （電話番号は半角数字ハイフン'-'を含めて入力してください。）
            </p>
            <v-col cols="12" sm="10" md="8">
              <v-text-field
                v-model="query"
                outlined
                hide-details
                placeholder="000-0000-0000"
                autofocus
              />
            </v-col>
            <v-col cols="12" sm="2" md="4">
              <v-btn color="primary" @click="doSearch"> 検索する </v-btn>
            </v-col>
            <v-alert
              v-if="alert"
              close-text="Close Alert"
              color="orange accent-4"
              dark
              dismissible
              class="ma-5"
            >
              クリニックが見つかりません。ほかの電話番号を入力いただけますか？
            </v-alert>
            <v-list-item three-line v-for="my in clinics" :key="my.id">
              <v-list-item-content class="my-n3">
                <v-list-item-title class="my-n3">
                  {{ my.name }}
                  <v-btn outlined rounded text v-on:click="selected(my)"
                    >選択</v-btn
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
export default {
  data() {
    return {
      query: "",
      loading: false,
      alert: false,
      error_message: "",
      clinics: [],
    };
  },
  watch: {
    "$route.query.q": {
      handler(newVal) {
        this.query = newVal;
      },
      immediate: true,
    },
  },
  computed: {},

  methods: {
    selected: function (clinic) {
      this.$router.push({ name: "patient_regster", params: { id: clinic.id } });
    },
    doSearch: async function () {
      try {
        await Firebase.firestore()
          .collection("Clinics")
          .where("call", "==", this.query)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                let temp_item = doc.data();
                temp_item.id = doc.id;
                this.clinics.push(temp_item);
              });
            }
          })
          .catch((error) => {
            console.log("Error getting prescriptios document:", error);
          });

        console.log(!Object.keys(this.clinics).length);
        if (!Object.keys(this.clinics).length) {
          this.alert = true;
        } else {
          this.alert = false;
        }
      } catch (e) {
        if (this.error == true) {
          this.$router.push({
            name: "error",
            params: { message: "表示できません。" },
          });
        } else {
          this.alert = true;
        }
      }
    },
  },
};
</script>
